import { Center } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/react';
import React from 'react';

type LoadingProps = {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

export const Loading: React.FC<LoadingProps> = ({ size = 'md' }) => {
    return (
        <Center>
            <Spinner size={size} />
        </Center>
    );
};
