import { axios } from "configs/axios";
import { QueryConfig } from "configs/react-query";
import { Nursery } from "features/admin/types";
import { useQuery } from "react-query";

export const getNurseriesByUser = (): Promise<Nursery[]> => {
  return axios.get("/nurseries/users");
};

type UseNurseriesOptions = {
  config?: QueryConfig<typeof getNurseriesByUser>;
};

export const useNurseriesByUser = ({ config }: UseNurseriesOptions = {}) => {
  return useQuery({
    ...config,
    queryKey: ["nurseries-users"],
    queryFn: () => getNurseriesByUser(),
  });
};
