import { Flex, Select as ChakraSelect, Icon } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";

interface SelectProps {
  leftIcon?: IconType;
  iconColor?: string;
  placeholder: string;
  onChange: (id: string) => void;
}

export const Select: React.FC<SelectProps> = ({
  children,
  leftIcon,
  iconColor,
  placeholder,
  onChange,
}) => {
  return (
    <Flex w="100%" borderRadius="24px" border="2px" borderColor="brand.pink">
      {leftIcon && (
        <Flex
          alignItems="center"
          justifyContent="center"
          color={iconColor}
          pl={"22px"}
        >
          <Icon as={leftIcon} boxSize="26px" />
        </Flex>
      )}
      <ChakraSelect
        w="100%"
        appearance="none"
        border="0px"
        boxSizing="border-box"
        placeholder={placeholder}
        h="40px"
        color="brand.red"
        _placeholder={{
          color: "grey.dark",
          fontSize: "16px",
        }}
        _focus={{ borderColor: "transparent" }}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
      >
        {children}
      </ChakraSelect>
    </Flex>
  );
};
