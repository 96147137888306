import { Button } from "@chakra-ui/button";
import { Flex, Heading, Text, VStack } from "@chakra-ui/layout";
import { Loading } from "components/Elements/Loading";
import { Select } from "components/Select";
import { Layout } from "features/auth/components/Layout";
import useAuth from "hooks/useAuth";
import React, { useState } from "react";
import { FaHome } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useNurseriesByUser } from "../api/getNurseriesByUser";

export const NurserySelect: React.FC = () => {
  const { user } = useAuth();
  const { data, isLoading } = useNurseriesByUser();
  const navigate = useNavigate();
  const [nursery, setNursery] = useState<string | null>(null);

  return (
    <Layout title="Se connecter">
      <Flex
        alignItems="center"
        flexDirection="column"
        justify={["space-between", "flex-start"]}
        height="calc(100vh - 190px)"
        w={["full", null, null, "container.lg"]}
        p={["20px"]}
      >
        <VStack spacing={4} w={["full", "350px"]} maxW="350px">
          <Heading
            fontWeight="normal"
            fontSize="32px"
            color="brand.primary"
            mt={8}
            mb={3}
            textAlign="center"
          >
            Bienvenue {user?.firstName}
          </Heading>
          <Text
            color="brand.red"
            fontSize="14px"
            fontWeight="700"
            textAlign="center"
            fontStyle="openSans"
          >
            Choix de votre structure :
          </Text>
          {isLoading ? (
            <Loading />
          ) : (
            <Select
              leftIcon={FaHome}
              placeholder="Séléctionnez"
              iconColor="brand.red"
              onChange={(id: string) => {
                setNursery(id);
              }}
            >
              {data?.map((nursery) => (
                <option key={nursery.id} value={nursery.id}>
                  {nursery.name}
                </option>
              ))}
            </Select>
          )}
        </VStack>

        <Button
          bg="brand.primary"
          type="submit"
          h="48px"
          isLoading={isLoading}
          isDisabled={!nursery}
          onClick={() => {
            navigate(`/directeur/${nursery}/dashboard`);
          }}
          mt="40px"
          w={["full", "400px"]}
          maxWidth="400px"
        >
          Accéder
        </Button>
      </Flex>
    </Layout>
  );
};
