import { axios } from "configs/axios";
import { UserResponse } from "features/auth";

export const getTeamToken = async (
  userId: string,
  nurseryId: string
): Promise<UserResponse> => {
  const res: UserResponse = await axios.get(
    `/auth/team/users/${userId}/nurseries/${nurseryId}`
  );

  return res;
};
