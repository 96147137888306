import { axios } from "configs/axios";
import { QueryConfig } from "configs/react-query";
import { useQuery } from "react-query";
import { Nursery } from "../types";

export const getNursery = ({
  nurseryId,
}: {
  nurseryId: string;
}): Promise<Nursery> => {
  return axios.get(`/nurseries/${nurseryId}`);
};

type UseNurseriesOptions = {
  nurseryId: string;
  config?: QueryConfig<typeof getNursery>;
};

export const useNursery = ({ config, nurseryId }: UseNurseriesOptions) => {
  return useQuery({
    ...config,
    queryKey: ["nursery", nurseryId],
    queryFn: () => getNursery({ nurseryId }),
  });
};
