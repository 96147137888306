import { Box, Center, Flex } from "@chakra-ui/layout";
import { ReactComponent as TeddySVG } from "assets/svg/teddy.svg";
import { ReactComponent as MCTDLogo } from "assets/svg/logo-mctd-white-h.svg";
import { Head } from "components/Head";
import React from "react";

export const Layout: React.FC<{ title: string }> = ({ title, children }) => {
  return (
    <>
      <Head title={title} />
      <Flex
        w={["full", "full", "full", "container.lg"]}
        minH="100vh"
        flexDirection="column"
        margin="0 auto"
        bg="white"
      >
        <Center
          bg="brand.primary"
          h="180px"
          position="relative"
          w="100%"
          color="white"
        >
          <MCTDLogo />
          <TeddySVG
            style={{
              height: "100px",
              width: "100px",
              position: "absolute",
              bottom: -40,
            }}
          />
        </Center>
        <Box>{children}</Box>
      </Flex>
    </>
  );
};
