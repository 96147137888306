const storagePrefix = "mctd_";

const storage = {
  getToken: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}token`) as string
    );
  },
  getSubToken: () => {
    return JSON.parse(
      window.localStorage.getItem(`sub${storagePrefix}token`) as string
    );
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  setSubToken: (token: string) => {
    window.localStorage.setItem(
      `sub${storagePrefix}token`,
      JSON.stringify(token)
    );
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  clearSubToken: () => {
    window.localStorage.removeItem(`sub${storagePrefix}token`);
  },
};

export default storage;
