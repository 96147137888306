import Icon from "@chakra-ui/icon";
import { Flex } from "@chakra-ui/layout";
import { ReactComponent as MenuIcon } from "assets/svg/menu-icon-home.svg";
import React from "react";
import { BsHouseDoor } from "react-icons/bs";
import { FaChild, FaCog, FaNewspaper, FaRegFileAlt } from "react-icons/fa";
import { MdFoodBank } from "react-icons/md";
import { useParams } from "react-router";
import { MenuItem } from "./MenuItem";

export const DirectorMenu: React.FC = () => {
  const { nurseryId } = useParams();
  return (
    <Flex
      bg="brand.primary"
      position={["fixed", null, null, "static"]}
      bottom="0"
      h={["56px", "93px", null, "100vh"]}
      maxW={["container.lg", null, null, "130px"]}
      w={["100%", null, null, "130px"]}
      flexDirection={["row", null, null, "column"]}
      justifyContent={["center", null, null, "flex-start"]}
      alignItems={["center", null, null, "flex-start"]}
    >
      <MenuItem
        to={`/directeur/${nurseryId}/dashboard`}
        label="Accueil"
        icon={<Icon as={MenuIcon} w="auto" h="auto" />}
      />
      <MenuItem
        to={`/directeur/${nurseryId}/actualites`}
        label="Actualités"
        icon={<Icon as={FaNewspaper} />}
      />
      <MenuItem
        to={`/directeur/${nurseryId}/factures`}
        label="Factures"
        icon={<Icon as={FaRegFileAlt} />}
      />
      <MenuItem
        to={`/directeur/${nurseryId}/enfants`}
        label="Enfants"
        icon={<Icon as={FaChild} />}
      />
      <MenuItem
        to={`/directeur/${nurseryId}/structures`}
        label="Structure"
        icon={<Icon as={BsHouseDoor} />}
      />
      <MenuItem
        to={`/directeur/${nurseryId}/repas`}
        label="Repas"
        icon={<Icon as={MdFoodBank} />}
      />
      <MenuItem
        to={`/directeur/${nurseryId}/parametres`}
        label="Paramètres"
        icon={<Icon as={FaCog} />}
      />
    </Flex>
  );
};
