import { Link, Text } from "@chakra-ui/layout";
import { NavLink } from "react-router-dom";

export const MenuItem: React.FC<{
  label: string;
  to: string;
  icon: React.ReactElement;
}> = ({ label, to, icon }) => {
  return (
    <Link
      as={NavLink}
      to={to}
      _activeLink={{
        bg: "white",
        color: "brand.primary",
        outline: "0",
        textDecoration: "none",
      }}
      _focus={{ boxShadow: "none" }}
      _hover={{ textDecoration: "none" }}
      display="flex"
      h={["100%", null, null, "92px"]}
      w={[1 / 5, null, null, "130px"]}
      bg="brand.primary"
      color="white"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      fontSize={["20px", "30px"]}
    >
      {icon}
      <Text
        fontSize={["8px", "12px"]}
        fontWeight="bold"
        letterSpacing="0.14px"
        mt="9px"
        textTransform="uppercase"
      >
        {label}
      </Text>
    </Link>
  );
};
