import { Layout, LayoutTeam } from "components/Layout";
import { Loading } from "components/Elements/Loading";
import React, { Suspense } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { lazyImport } from "utils/lazyImport";
import { Authorization } from "configs/authorization";
import { AdminMenu, DirectorMenu } from "components/Menu";
import { NurserySelect } from "features/director/pages/NurserySelect";
import { TeamMenu } from "components/Menu/TeamMenu";

const { AdminRoutes } = lazyImport(
  () => import("features/admin"),
  "AdminRoutes"
);

const { DirectorRoutes } = lazyImport(
  () => import("features/director"),
  "DirectorRoutes"
);

const { TeamRoutes } = lazyImport(() => import("features/team"), "TeamRoutes");

const App = ({ Menu }: { Menu: React.FC }) => {
  return (
    <Layout Menu={Menu}>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </Layout>
  );
};

const AppTeam = ({ Menu }: { Menu: React.FC }) => {
  return (
    <LayoutTeam Menu={Menu}>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </LayoutTeam>
  );
};

export const adminRoutes = [
  {
    path: "/",
    element: (
      <Authorization allowedRoles={["ADMIN"]} forbiddenFallback={<></>}>
        <App Menu={AdminMenu} />
      </Authorization>
    ),
    children: [
      { path: "/", element: <Navigate to="/directeur/dashboard" /> },
      { path: "/admin/*", element: <AdminRoutes /> },
      { path: "*", element: <Navigate to="/admin/dashboard" /> },
    ],
  },
];

export const teamRoutes = [
  {
    path: "/",
    element: (
      <Authorization allowedRoles={["EQUIPE"]} forbiddenFallback={<></>}>
        <AppTeam Menu={TeamMenu} />
      </Authorization>
    ),
    children: [
      { path: "/", element: <Navigate to="/equipe/dashboard" /> },
      { path: "/equipe/*", element: <TeamRoutes /> },
      { path: "*", element: <Navigate to="/equipe/dashboard" /> },
    ],
  },
];

export const directorRoutes = [
  {
    path: "/directeur/structures",
    element: (
      <Authorization
        allowedRoles={["ADMIN", "DIRECTEUR", "REFTECHNIQUE"]}
        forbiddenFallback={<></>}
      >
        <NurserySelect />
      </Authorization>
    ),
  },
  {
    path: "/",
    element: (
      <Authorization
        allowedRoles={["ADMIN", "DIRECTEUR", "REFTECHNIQUE"]}
        forbiddenFallback={<></>}
      >
        <App Menu={DirectorMenu} />
      </Authorization>
    ),
    children: [
      { path: "/", element: <Navigate to="/directeur/structures" /> },
      { path: "/directeur/:nurseryId/*", element: <DirectorRoutes /> },
      { path: "*", element: <Navigate to="/directeur/structures" /> },
    ],
  },
];
