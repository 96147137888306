import { axios } from "configs/axios";
import { QueryConfig } from "configs/react-query";
import { User } from "features/admin/types";
import { useQuery } from "react-query";

export const getTeam = (nurseryId: number): Promise<User[]> => {
  return axios.get(`/nurseries/${nurseryId}/team`);
};

type UseTeamOptions = {
  config?: QueryConfig<typeof getTeam>;
  nurseryId: number;
};

export const useTeam = ({ config, nurseryId }: UseTeamOptions) => {
  return useQuery({
    ...config,
    queryKey: ["team", nurseryId],
    queryFn: () => getTeam(nurseryId),
  });
};
