import { Button } from "@chakra-ui/button";
import { Box, Center, Flex } from "@chakra-ui/layout";
import { useDisclosure } from "@chakra-ui/react";
import { ReactComponent as MCTDLogo } from "assets/svg/logo-mctd-white-h.svg";
import useAuth from "hooks/useAuth";
import React from "react";
import storage from "utils/storage";
import { ChangeUserModal } from "features/team/components/ChangeUserModal/ChangeUserModal";
import { useTeam } from "features/director/api/getTeam";
import { getTeamToken } from "features/director/api/getTeamToken";
import { useNursery } from "features/admin/api/getNursery";

interface LayoutTeamProps {
  Menu: React.FC;
}

export const LayoutTeam: React.FC<LayoutTeamProps> = ({ children, Menu }) => {
  const { user, logOutSub, takeIdentity } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: team } = useTeam({
    nurseryId: Number(user?.nurseryId),
  });
  const { data: nursery } = useNursery({
    nurseryId: user?.nurseryId || "",
  });

  const onChangeUser = async (userId: number) => {
    if (user?.nurseryId) {
      const { accessToken } = await getTeamToken(
        String(userId),
        user?.nurseryId || ""
      );

      takeIdentity(accessToken);
    }
  };

  return (
    <Flex
      w={["full", null, null, "container.lg"]}
      minH="100vh"
      paddingBottom={["60px", null, null, "0"]}
      flexDirection={["column", null, null, "row-reverse"]}
      margin="0 auto"
      bg="brand.bg"
      position="relative"
    >
      {storage.getSubToken() && (
        <Button
          w="200px"
          bg="brand.gold"
          position="fixed"
          top="10px"
          right="10px"
          onClick={logOutSub}
        >
          Retour en Admin
        </Button>
      )}
      <Box
        w="100%"
        px={["0", null, "20px", "15px"]}
        h={["calc(100vh - 50px)", "calc(100vh - 96px)", "100vh"]}
        overflowY="scroll"
      >
        <Center bg="brand.primary" h={["50px", "120px"]} w="100%" color="white">
          <MCTDLogo
            width="190px"
            onClick={() => {
              if (nursery?.allowTeamSwap) {
                onOpen();
              }
            }}
            cursor={nursery?.allowTeamSwap ? "pointer" : "auto"}
          />
        </Center>
        {children}
      </Box>
      <Menu />
      {nursery?.allowTeamSwap && (
        <ChangeUserModal
          isOpen={isOpen}
          onClose={onClose}
          onChangeUser={onChangeUser}
          isLoading={false}
          team={team || []}
        />
      )}
    </Flex>
  );
};
