import { Button } from "@chakra-ui/button";
import { Box, Center, Flex } from "@chakra-ui/layout";
import { ReactComponent as MCTDLogo } from "assets/svg/logo-mctd-white-h.svg";
import useAuth from "hooks/useAuth";
import React from "react";
import storage from "utils/storage";

interface LayoutProps {
  Menu: React.FC;
}

export const Layout: React.FC<LayoutProps> = ({ children, Menu }) => {
  const { logOutSub, user } = useAuth();

  return (
    <Flex
      w={["full", null, null, "container.lg"]}
      minH="100vh"
      paddingBottom={["60px", null, null, "0"]}
      flexDirection={["column", null, null, "row-reverse"]}
      margin="0 auto"
      bg="brand.bg"
      position="relative"
    >
      {storage.getSubToken() && (
        <Button
          w="200px"
          bg="brand.gold"
          position="fixed"
          top="10px"
          right="10px"
          onClick={logOutSub}
        >
          Retour en Admin
        </Button>
      )}
      <Box
        w="100%"
        px={["0", null, "20px", "15px"]}
        h={["calc(100vh - 50px)", "calc(100vh - 96px)", "100vh"]}
        overflowY="scroll"
      >
        <Center bg="brand.primary" h={["50px", "120px"]} w="100%" color="white">
          <MCTDLogo width="190px" />
        </Center>
        <Flex
          bg="white"
          fontSize="10pt"
          color="grey.darker"
          justifyContent="center"
          shadow="bottomLight"
          h="20pt"
          padding="4px"
        >
          Connecte en tant que : {user?.firstName}{" "}
          {user?.nurseryName ? `- ${user?.nurseryName}` : ""}
        </Flex>
        {children}
      </Box>
      <Menu />
    </Flex>
  );
};
