import Axios, { AxiosRequestConfig } from "axios";
import storage from "utils/storage";
import { API_URL } from "./";

import { createStandaloneToast } from "@chakra-ui/react";

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getSubToken() ?? storage.getToken();
  if (token && config.headers) {
    config.headers.authorization = `Bearer ${token}`;
    config.headers.Accept = "application/json";
  }

  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data[0]?.message || error.message;
    const toast = createStandaloneToast();

    if (!error.response || error.response.status !== 400) {
      toast({
        title: "Il y a un problème",
        description: message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    return Promise.reject(error);
  }
);
