import Icon from "@chakra-ui/icon";
import { Flex } from "@chakra-ui/layout";
import { ReactComponent as MenuIcon } from "assets/svg/menu-icon-home.svg";
import React from "react";
import { BsHouseDoor } from "react-icons/bs";
import { FaCog, FaRegUser } from "react-icons/fa";
import { MenuItem } from "./MenuItem";

export const AdminMenu: React.FC = () => {
  return (
    <Flex
      bg="brand.primary"
      position={["fixed", null, null, "static"]}
      bottom="0"
      h={["56px", "93px", null, "100vh"]}
      maxW={["container.lg", null, null, "130px"]}
      w={["100%", null, null, "130px"]}
      flexDirection={["row", null, null, "column"]}
      justifyContent={["center", null, null, "flex-start"]}
      alignItems={["center", null, null, "flex-start"]}
    >
      <MenuItem
        to="/admin/dashboard"
        label="Accueil"
        icon={<Icon as={MenuIcon} w="auto" h="auto" />}
      />
      <MenuItem
        to="/admin/utilisateurs"
        label="Utilisateurs"
        icon={<Icon as={FaRegUser} />}
      />
      <MenuItem
        to="/admin/structures"
        label="Structures"
        icon={<Icon as={BsHouseDoor} />}
      />
      <MenuItem
        to="/admin/parametres"
        label="Paramètres"
        icon={<Icon as={FaCog} />}
      />
    </Flex>
  );
};
