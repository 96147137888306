import { Loading } from "components/Elements/Loading";
import { User } from "features/admin/types";
import { UserResponse } from "features/auth";
import { getUser } from "features/auth/api/getUser";
import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import storage from "utils/storage";

interface AuthContextValue {
  user: User | undefined;
  loginSuccess: (data: UserResponse) => void;
  logOut: () => void;
  takeIdentity: (data: string) => void;
  logOutSub: () => void;
}

const initialValue = {
  user: undefined,
  loginSuccess: (data: UserResponse) => {},
  logOut: () => {},
  logOutSub: () => {},
  takeIdentity: (data: string) => {},
};

const AuthContext = createContext<AuthContextValue>(initialValue);

export const AuthProvider: React.FC = ({ children }) => {
  const queryClient = useQueryClient();
  const key = "auth-user";
  const loadUser = async () => {
    try {
      if (storage.getToken()) {
        const user = await getUser();
        return user;
      }
    } catch (e) {
      logOut();
    }

    return undefined;
  };

  const {
    data: user,
    error,
    isLoading,
    isIdle,
    isSuccess,
  } = useQuery<User | undefined, Error>(key, loadUser);

  const setUser = useCallback(
    (data: User) => queryClient.setQueryData(key, data),
    [queryClient]
  );

  const loginSuccess = useMemo(
    () =>
      ({ accessToken, user }: UserResponse) => {
        storage.setToken(accessToken);
        setUser(user);
      },
    [setUser]
  );

  const logOut = useCallback(() => {
    storage.clearToken();
    storage.clearSubToken();
    queryClient.clear();
    window.location.assign(window.location.origin as unknown as string);
  }, [queryClient]);

  const logOutSub = useCallback(() => {
    storage.clearSubToken();
    queryClient.clear();
    window.location.assign(window.location.origin as unknown as string);
  }, [queryClient]);

  const takeIdentity = useCallback((token) => {
    storage.setSubToken(token);
    window.location.assign(window.location.origin as unknown as string);
  }, []);

  const providerValue = useMemo(
    () => ({ user, loginSuccess, logOut, takeIdentity, logOutSub }),
    [user, loginSuccess, logOut, takeIdentity, logOutSub]
  );

  if (isSuccess) {
    return (
      <AuthContext.Provider value={providerValue}>
        {children}
      </AuthContext.Provider>
    );
  }

  if (isLoading || isIdle) {
    return <Loading />;
  }

  if (error) {
    return <div>{String(error)}</div>;
  }

  return null;
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export default useAuth;
