import useAuth from "hooks/useAuth";
import { Navigate, useRoutes } from "react-router-dom";
import { lazyImport } from "utils/lazyImport";
import { adminRoutes, directorRoutes, teamRoutes } from "./protected";

const { AuthRoutes } = lazyImport(() => import("features/auth"), "AuthRoutes");

export const AppRoutes = () => {
  const auth = useAuth();

  const publicRoutes = [
    { path: "/auth/*", element: <AuthRoutes /> },
    { path: "*", element: <Navigate to="/auth/login" /> },
  ];

  let routes = publicRoutes;
  if (auth.user) {
    switch (auth.user.role) {
      case "ADMIN":
        routes = adminRoutes;
        break;

      case "REFTECHNIQUE":
      case "DIRECTEUR":
        routes = directorRoutes;
        break;

      case "EQUIPE":
        routes = teamRoutes;
        break;
    }
  }

  const element = useRoutes(routes);

  return <>{element}</>;
};
