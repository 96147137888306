import "@fontsource/open-sans";
import "@fontsource/galada";
import "@fontsource/delius";

import { ChakraProvider } from "@chakra-ui/react";
import { Loading } from "components/Elements/Loading";
import theme from "configs/theme";
import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { queryClient } from "configs/react-query";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "hooks/useAuth";

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense fallback={<Loading />}>
      <HelmetProvider>
        <ChakraProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <Router>{children}</Router>
            </AuthProvider>
          </QueryClientProvider>
        </ChakraProvider>
      </HelmetProvider>
    </React.Suspense>
  );
};
