import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "#E0DBD6",
      },
    },
  },
  fonts: {
    heading: "Galada",
    body: "Open Sans",
    name: "Delius",
  },
  colors: {
    brand: {
      primary: "#EA6A6A",
      bg: "#F2EFEC",
      gold: "#EFBB52",
      green: "#6CDD93",
      red: "#EA6A6A",
      pink: "#FFD9D9",
      blue: "#43B7E5",
      lightblue: "#7DD6F9",
      grey: "#D3D3D3",
      lightgrey: "#DCDCDC",
    },
    daily: {
      sleep: "#002A7B",
      hygiene: "#00DDE6",
      activity: "#E67C00",
      meal: "#A52AC3",
      medic: "#A82B2B",
    },
    gender: {
      Male: "#7DD6F9",
      Female: "#EA6AB9",
    },
    grey: {
      darker: "#766B6B",
      dark: "#868E96",
      2: "#8D8B8B",
      3: "#707070",
    },
    checkboxGreen: {
      500: "#6CDD93",
      200: "#6CDD93",
    },
    percent: {
      red: "#CE0000",
      green: "#6CDD93",
    },
  },
  shadows: {
    bottomXs: "0 5px 5px -5px #000",
    bottomLight: "0pt 0pt 5pt 5pt rgba(0, 0, 0, 0.05)",
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "bold",
        borderRadius: "3xl",
        fontSize: "14px",
        lineHeight: "12px",
        color: "white",
        letterSpacing: 1.4,
        textTransform: "uppercase",
        justifyContent: "center",
        width: "full",
      },
      variants: {
        small: {
          fontWeight: "bold",
          borderRadius: "3xl",
          fontSize: "8px",
          lineHeight: "7px",
          color: "white",
          letterSpacing: 0.64,
          textTransform: "uppercase",
          justifyContent: "center",
          _hover: {
            background: "",
          },
        },

        round: {
          p: 0,
        },
      },
    },

    Select: {
      baseStyle: {
        field: {
          fontSize: "16px",
          bg: "white",
          borderRadius: "md",
          border: 0,
        },
      },
    },
  },
});

export default theme;
