import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Icon,
  StackDivider,
} from "@chakra-ui/react";
import { User } from "features/admin/types";
import useAuth from "hooks/useAuth";
import { FaChevronRight, FaRegCheckCircle } from "react-icons/fa";

interface ChangeUserModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onChangeUser: (userId: number) => void;
  team: User[];
}

export const ChangeUserModal: React.FC<ChangeUserModalProps> = ({
  isOpen,
  onClose,
  onChangeUser,
  team,
}) => {
  const { user: currentUser } = useAuth();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Changer d'utilisateur</ModalHeader>
        <ModalBody
          fontWeight="bold"
          color="grey.darker"
          padding={0}
          borderTopLeftRadius="10px"
        >
          <Stack spacing={0} divider={<StackDivider borderColor="gray.200" />}>
            {team.map((user, index) => {
              const isCurrentUser = user.id === currentUser?.id;
              return (
                <Flex
                  key={`change-user-${user.id}`}
                  justify="space-between"
                  alignItems="center"
                  py="15px"
                  px="15px"
                  mt={0}
                  cursor={isCurrentUser ? "initial" : "pointer"}
                  _hover={
                    isCurrentUser
                      ? undefined
                      : {
                          opacity: 0.8,
                          bgColor: "brand.bg",
                        }
                  }
                  onClick={() => { onChangeUser(user.id) }}
                >
                  <Text fontSize="20px" color={isCurrentUser ? "brand.red" : "gray.2"}>
                    {user.firstName} {user.lastName}
                  </Text>
                  <Icon as={isCurrentUser ? FaRegCheckCircle : FaChevronRight} fontSize="20px" color="brand.red" />
                </Flex>
              );
            })}
          </Stack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
